import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    carrito: JSON.parse(localStorage.getItem("carrito")) || {
      productos: [],
      subtotal: 0,
    },
    alCarrito: {
      dialogAgregar: false,
      seleccionado: null,
    },
    token: localStorage.getItem("token") || null,
    usuario: localStorage.getItem("usuario")
      ? JSON.parse(localStorage.getItem("usuario"))
      : null,
    snackbar: null,
    menuCategorias: false
  },
  mutations: {
    agregarCarrito(state, payload) {
      let index = state.carrito.productos.findIndex(
        (val) => val._id == payload._id
      );
      if (index > -1) {
        state.carrito.productos[index].cantidad += payload.cantidad;
        state.carrito.productos[index].total += payload.total;
      } else {
        state.carrito.productos.push(payload);
      }
      state.carrito.subtotal += payload.total;
      localStorage.setItem(
        "carrito",
        JSON.stringify({
          productos: state.carrito.productos,
          subtotal: state.carrito.subtotal,
        })
      );
    },
    quitarCarrito(state, payload) {
      state.carrito.subtotal -= payload.total;
      let index = state.carrito.productos.findIndex(
        (val) => val._id == payload._id
      );
      state.carrito.productos.splice(index, 1);
      localStorage.setItem(
        "carrito",
        JSON.stringify({
          productos: state.carrito.productos,
          subtotal: state.carrito.subtotal,
        })
      );
    },
    limpiarCarrito(state) {
      state.carrito.productos = [];
      state.carrito.subtotal = 0;
      localStorage.setItem(
        "carrito",
        JSON.stringify({
          productos: state.carrito.productos,
          subtotal: state.carrito.subtotal,
        })
      );
    },
    limpiarItemCarrito(state, index) {
      state.carrito.subtotal -= state.carrito.productos[index].total;
      state.carrito.productos[index].cantidad = 0;
      state.carrito.productos[index].total = 0;
      localStorage.setItem(
        "carrito",
        JSON.stringify({
          productos: state.carrito.productos,
          subtotal: state.carrito.subtotal,
        })
      );
    },
    dialogAgregarCarrito(state, { item, estado }) {
      state.alCarrito.dialogAgregar = estado;
      state.alCarrito.seleccionado = item;
    },
    abrirSnackbar(state, payload) {
      state.snackbar = payload;
    },
    cerrarSnackbar(state) {
      state.snackbar = null;
    },
    setUsuario(state, payload) {
      state.usuario = payload.usuario;
      state.token = payload.token;
      localStorage.setItem("token", state.token);
      localStorage.setItem("usuario", JSON.stringify(state.usuario));
    },
    removeUsuario(state) {
      state.usuario = null;
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("usuario");
    },
    setMenuCategorias(state, payload) {
      state.menuCategorias = payload
    }
  },
  actions: {
    cambiarMenuCategorias(context) {
      context.commit('setMenuCategorias', !context.state.menuCategorias)
    }
  },
  modules: {},
});
