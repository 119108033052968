<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="alCarrito.dialogAgregar"
    persistent
  >
    <template v-slot:default="dialog">
      <v-card v-if="alCarrito.seleccionado">
        <v-toolbar color="primary" dark
          >Agregar {{ alCarrito.seleccionado.nombre }} al carrito
        </v-toolbar>
        <v-card-text>
          <div class="text-h6 pa-6">¿Cuantas unidades?</div>
          <v-select
            v-if="alCarrito.seleccionado.stock > 0"
            :items="cantidadStock"
            v-model="cantidadSolicitada"
            no-data-text="No hay disponibilidad"
            filled
            type="number"
            class="text-h6"
          ></v-select>
          <div class="text-h4 text-left pa-2">$ {{ monto }}</div>
          <div class="text-h6 text-right">
            Subtotal ... $ {{ carritoSubtotal }}
          </div>
          <v-btn block dark @click="confirmar()" color="green" class="mt-4"
            >{{alCarrito.seleccionado.editar ? 'Confirmar' : 'Agregar al carrito'}}</v-btn
          >
          <v-btn block dark @click="ver()" color="primary" class="mt-2" v-if="$route.name != 'Productos'"
            >Ver producto</v-btn
          >
          <v-btn block dark @click="irCarrito()" color="primary" class="mt-2" v-else
            >Ver carrito</v-btn
          >
          <v-btn block dark @click="chauCarrito()" color="red" text class="mt-2" v-if="alCarrito.seleccionado.editar"
            >Quitar del carrito</v-btn
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text dark @click="cerrar()" color="red">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Carrito_Agregar",
  data: () => ({
    item: {},
    cantidadSolicitada: 1,
  }),
  computed: {
    ...mapState(["carrito", "alCarrito"]),
    cantidadStock() {
      this.cantidadSolicitada = 1;
      if (this.alCarrito.seleccionado.editar)
        this.cantidadSolicitada = this.alCarrito.seleccionado.cantidad;
      let array = [];
      let max = this.alCarrito.seleccionado.max_venta
        ? this.alCarrito.seleccionado.max_venta
        : this.alCarrito.seleccionado.stock;
      for (let i = 1; i <= max; i++) array.push(i);
      return array;
    },
    monto() {
      let consultarPrecio = this.alCarrito.seleccionado.precios.reduce((o, p) => this.cantidadSolicitada >= p.cantidad ? o = p : o, { cantidad: 0, precio: 0 })
      return consultarPrecio.precio * this.cantidadSolicitada;
    },
    carritoSubtotal() {
      return (
        this.carrito.subtotal -
        (this.alCarrito.seleccionado.editar
          ? this.alCarrito.seleccionado.total
          : 0) +
        this.monto
      );
    },
  },
  watch: {},
  methods: {
    ...mapMutations([
      "dialogAgregarCarrito",
      "agregarCarrito",
      "limpiarItemCarrito",
      "abrirSnackbar",
      "quitarCarrito"
    ]),
    confirmar() {
      let nombre = this.alCarrito.seleccionado.nombre;
      if (this.alCarrito.seleccionado.editar) {
        this.limpiarItemCarrito(
          this.carrito.productos.findIndex(
            (val) => val._id == this.alCarrito.seleccionado._id
          )
        );
      } else {
        this.abrirSnackbar({
          texto: `Se añadio ${nombre} a tu carrito.`,
          color: "green",
          timeout: 2000,
        });
        if (this.$route.name != "Carrito") this.$router.push(`/carrito`);
      }
      this.agregarCarrito({
        ...this.alCarrito.seleccionado,
        cantidad: this.cantidadSolicitada,
        total: this.monto,
      });
      this.cerrar();
    },
    cerrar() {
      this.dialogAgregarCarrito({ item: null, estado: false });
    },
    ver() {
      let slug = this.alCarrito.seleccionado.slug;
      this.cerrar();
      this.$router.push(`/productos/${slug}`);
    },
    irCarrito() {
      this.cerrar()
      this.$router.push({name: 'Carrito'})
    },
    chauCarrito() {
      if(confirm("¿Quitar este producto del carrito?")) {
        this.quitarCarrito(this.alCarrito.seleccionado)
        this.cerrar();
        if(this.$route.name == 'Carrito' && !this.carrito.productos.length) this.$router.push({name: 'Tienda'})
      }
    }
  },
};
</script>
