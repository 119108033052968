import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: () => import('../views/Homepage.vue')
  },
  {
    path: '/tienda/:categoria?',
    name: 'Tienda',
    component: () => import('../views/Tienda.vue'),
    params: true
  },
  {
    path: '/productos/:slug',
    name: 'Productos',
    component: () => import('../views/Productos.vue'),
    params: true
  },
  {
    path: '/carrito',
    name: 'Carrito',
    component: () => import('../views/Carrito.vue'),
  },
  {
    path: '/comprar',
    name: 'Comprar',
    component: () => import('../views/Comprar.vue'),
    params: true
  },
  {
    path: '/ingresar',
    name: 'Ingresar',
    component: () => import('../views/Ingresar.vue'),
    params: true
  },
  {
    path: '/registro',
    name: 'Registro',
    component: () => import('../views/Registro.vue'),
  },
  {
    path: '/cuenta',
    name: 'Cuenta',
    component: () => import('../views/Cuenta.vue'),
    params: true
  },
  {
    path: '/pedido/:_id',
    name: 'Pedido',
    component: () => import('../views/Pedido.vue'),
    params: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
