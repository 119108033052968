<template>
  <v-app id="inspire">
    <v-system-bar app>
      <v-icon @click="irCuenta()">mdi-account</v-icon> Hola {{ usuarioNombre }}
      <v-spacer></v-spacer>
      <v-btn text @click="usuario ? salir() : ingresar()">{{
        usuario ? "Salir" : "Ingresar"
      }}</v-btn>
    </v-system-bar>

    <v-app-bar app>
      <v-app-bar-nav-icon
        @click.stop="$store.dispatch('cambiarMenuCategorias')"
      ></v-app-bar-nav-icon>
      <v-toolbar-title @click="irInicio"
        ><v-img
          src="@/assets/logo_texto.png"
          max-height="40"
          max-width="150"
        ></v-img
      ></v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn to="/cuenta" icon>
        <v-icon>mdi-account</v-icon>
      </v-btn>
      <v-badge
        bottom
        left
        overlap
        :content="carrito.productos.length"
        v-if="carrito.productos.length"
        ><v-icon @click="irCarrito()">mdi-cart</v-icon> $
        {{ carrito.subtotal }}</v-badge
      >
    </v-app-bar>

    <v-main class="grey lighten-2">
      <router-view></router-view>
      <Carrito_Agregar></Carrito_Agregar>
      <v-dialog
        v-model="$store.state.menuCategorias"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="$store.dispatch('cambiarMenuCategorias')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark icon @click="irCuentaDesdeMenu()">
                <v-icon>mdi-account</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-subheader>Categorias</v-subheader>
          <v-divider></v-divider>
          <v-virtual-scroll :items="categorias" height="500" item-height="64">
            <template v-slot:default="{ item }">
              <v-list-item :key="item._id" @click="irCategoria(item)">
                <v-list-item-avatar tile>
                  <v-img :src="item.foto"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    :class="
                      $route.query.q && $route.query.q == item
                        ? 'font-weight-bold'
                        : ''
                    "
                  >
                    {{ item.nombre }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-dialog>
      <v-fab-transition>
        <v-btn
          fab
          dark
          large
          color="primary"
          fixed
          right
          bottom
          v-show="
            $route.name && !$route.name.includes('Tienda', 'Carrito', 'Comprar')
          "
        >
          <v-icon dark @click="irWhatsApp()">mdi-chat</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-main>
    <v-snackbar
      v-if="snackbar"
      v-model="snackbar"
      :timeout="snackbar.timeout ? snackbar.timeout : -1"
      top
      :color="snackbar.color ? snackbar.color : 'blue'"
      elevation="24"
    >
      {{ snackbar.texto }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="cerrarSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Carrito_Agregar from "./components/Carrito/Agregar";
import { mapMutations, mapState } from "vuex";
export default {
  metaInfo: {
    title: "Venta de Insumos para Sublimación al por Mayor y Menor",
    titleTemplate: "%s | Cayetano Grafica",
    meta: [
      {
        name: "description",
        content:
          "Ventas al por Mayor y Menor. Realizamos envios a todo el pais.",
      },
      {
        property: "og:title",
        content: "Cayetano Grafica - Insumos para Sublimación",
      },
      { property: "og:site_name", content: "Cayetano Grafica" },
      { property: "og:type", content: "website" },
      { name: "robots", content: "index,follow" },
    ],
  },
  components: {
    Carrito_Agregar,
  },
  data: () => ({
    categorias: [],
  }),
  computed: {
    ...mapState(["carrito", "snackbar", "usuario"]),
    usuarioNombre() {
      return this.usuario && this.usuario.nombre
        ? this.usuario.nombre
        : "Visitante";
    },
  },
  methods: {
    ...mapMutations(["cerrarSnackbar", "removeUsuario", "setUsuario"]),
    irInicio() {
      if (this.$route.path != "/") this.$router.push("/");
    },
    irCarrito() {
      if (this.$route.path != "/carrito") this.$router.push("/carrito");
    },
    irCategoria(categoria) {
      let url = `/tienda/` + categoria.slug;
      if (this.$route.fullPath != url) this.$router.push(url);
      this.$store.dispatch("cambiarMenuCategorias");
    },
    ingresar() {
      if (this.$route.name != "Ingresar")
        this.$router.push({ name: "Ingresar" });
    },
    salir() {
      if (confirm("¿Salir de su cuenta?")) {
        if (this.$cookies.isKey("token")) this.$cookies.remove("token");
        if (this.$cookies.isKey("usuario")) this.$cookies.remove("usuario");
        this.removeUsuario();
        if (this.$route.name != "Homepage")
          this.$router.push({ name: "Homepage" });
      }
    },
    irCuentaDesdeMenu() {
      if (this.$route.path != "/cuenta") this.$router.push("/cuenta");
      this.$store.dispatch("cambiarMenuCategorias");
    },
    irWhatsApp() {
      window.open(
        "https://api.whatsapp.com/send?phone=5493517635715&text=Hola,%20quiero%20más%20información"
      );
    },
    irCuenta() {
      this.$router.push({ name: "Cuenta" });
    },
    async obtenerCategorias() {
      try {
        const categorias = await this.axios.get("/categorias");
        this.categorias = categorias.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.obtenerCategorias();
  },
};
</script>
