import Vue from "vue";
import Vuetify from "vuetify/lib";
import VueAxios from "vue-axios";
import axios from "axios";
import Vuelidate from "vuelidate";
import VueCookies from "vue-cookies";
import store from "../store/index";
import router from "../router/index"
import es from 'vuetify/es5/locale/es'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
const moment = require("moment");
require("moment/locale/es");

Vue.use(Vuelidate);

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(Vuetify);

Vue.use(require("vue-moment"), {
  moment,
});

axios.defaults.baseURL = 
  process.env.NODE_ENV == "development"
    ? process.env.VUE_APP_DEV_API
    : process.env.VUE_APP_API;

axios.interceptors.request.use(function(config) {
  if (store.state.token)
    config.headers.common["access-token"] = store.state.token;
  return config;
});

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    console.log(error)
    if (error.response.status === 401) {
      if (store.state.token) store.commit("removeUsuario");
      router.push({ name: "Ingresar" });
    }
    return Promise.reject(error);
  }
);

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
});
